<template>
    <v-container fluid>
        <v-form @submit.prevent="getIntegrationDatas">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ $t('menu_new_data') }}</v-subheader>
                            </v-col>
                        </v-row>
                   </v-card-text>

                    <v-card-actions class="px-0 py-5">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="8" class="text-right">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>


                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat light>
            <v-card-text class="px-0">
                <v-data-table :headers="headers" :items="integration_dataItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalIntegrationDatas" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.created_at="{ item }">
                        {{  item.created_at ? $moment(item.created_at).format('YYYY-MM-DD HH:mm') : ''}}
                    </template>
                    <template v-slot:item.guide_name="{ item }">

                        <router-link v-if="item.type == 'station'" :to="{name: 'station.edit', params: {id: item.guide_id} }"
                             tag="span" exact  class="text-decoration-underline" :style="{ cursor: 'pointer', color: '#1976d2'}">{{  item.guide_name }}</router-link>
                        <router-link v-else-if="item.type == 'condition'" :to="{name: 'condition.edit', params: {id: item.guide_id} }"
                                     tag="span" exact class="text-decoration-underline" :style="{ cursor: 'pointer' , color: '#1976d2'}">{{  item.guide_name }}</router-link>
                        <router-link v-else-if="item.type == 'container_type'" :to="{name: 'container_type.edit', params: {id: item.guide_id} }"
                                     tag="span" exact class="text-decoration-underline" :style="{ cursor: 'pointer' , color: '#1976d2'}">{{  item.guide_name }}</router-link>

                        <v-tooltip v-else-if="item.type == 'status_railway'" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-on="on" @click="showDialogStatus(item)">{{  item.guide_name }}</span>
                            </template>
                            <span>{{$t('assign_the_status_of_the_railway')}}</span>
                        </v-tooltip>

                        <v-tooltip v-else bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-on="on">{{  item.guide_name }}</span>
                            </template>
                            <span>{{$t('assign_the_status_of_the_railway')}}</span>
                        </v-tooltip>

                    </template>

                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteIntegrationData(item)" :title="$t('delete')">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-show="totalIntegrationDatas > perPage" v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="dialogEditStatus" max-width="800" persistent eager>
            <v-card>
                <ValidationObserver ref="observerForm" v-slot="{ invalid, validated, passes, validate }">
                    <v-card-title>
                        <span class="headline">
                            {{ $t('assign_a_status') }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="status" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="status" :items="statusItems"
                                                    :disabled="loadingStatuses"
                                                    prepend-icon="mdi-format-list-checks" item-text="name" item-value="id"
                                                    :label="$t('status')" color="primary"
                                                    clearable></v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="gray darken-1" class="ml-1 ml-sm-2" text @click="closeDialogStatus">
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text :loading="loadingSaveStatus"
                               :disabled="invalid || loadingSaveStatus" class="mr-1 mr-sm-2"
                               @click="saveStatus()">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"

    export default {
        name: "IntegrationDatas",
        components: {

            ValidationObserver,
            ValidationProvider
        },
        data() {
            return {
                sortBy: "id",
                sortDir: false,
                loading: false,
                options: {},
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalIntegrationDatas: 0,
                integration_dataItems: [],
                headers: [
                    {
                        text: this.$t('name_integration_data'),
                        align: "left",
                        sortable: true,
                        value: "guide_name"
                    },
                    {
                        text: this.$t('integration_date'),
                        align: "left",
                        sortable: true,
                        value: "created_at",
                        width: 150,
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
                dialogEditStatus:false,
                loadingStatuses: false,
                status: null,
                statusItems:[],
                loadingSaveStatus:false,
                status_railway_id: null,
                integration_id: null
            }
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.getStatus()
        },
        watch: {
            options: {
                handler() {
                    this.getIntegrationDatas()
                },
                deep: false
            }
        },
        methods: {

            closeDialogStatus(item){
                this.dialogEditStatus = false
                this.status_railway_id = null
                this.integration_id = null
                this.status = null
            },
            showDialogStatus(item){
                this.dialogEditStatus = true
                this.status_railway_id = item.guide_id
                this.integration_id = item.id
            },
            async getStatus() {
                this.loadingStatuses = true
                let params = {};
                params.filter = 'search'

                await this.$http
                    .get("admin/status", {
                        params: params,
                    })
                    .then(res => {
                        this.statusItems = res.body.data;
                    })
                    .catch(err => {
                        this.statusItems = []
                        this.$toastr.error(this.$t('failed_to_get_status'))
                    })
                    .finally(end => {
                        this.loadingStatuses = false
                    })
            },
            async saveStatus() {
                var _this = this
                this.progress = 0
                this.loadingSaveStatus = true
                var formData = new FormData()

                if (
                    this.status_railway_id !== undefined &&
                    this.status_railway_id != null &&
                    this.status_railway_id !== ''
                ) {
                    formData.append('status_railways_id', this.status_railway_id)

                }

                if (
                    this.status !== undefined &&
                    this.status != null &&
                    this.status !== ''
                ) {
                    formData.append('status_id', this.status)
                }

                if (
                    this.integration_id !== undefined &&
                    this.integration_id != null &&
                    this.integration_id !== ''
                ) {
                    formData.append('integration_id', this.integration_id)
                }


                await this.$http
                    .put(`admin/status_railway`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('status_railway_has_been_updated'))
                        this.integration_dataItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalIntegrationDatas = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                        _this.closeDialogStatus()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('status_railway_has_not_been_updated'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loadingSaveStatus = false
                    })


            },
            editIntegrationData(item) {
                this.$router.push({
                    name: 'integration_data.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async getIntegrationDatas() {
                var _this = this
                this.progress = 0
                this.loading = true
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options
                let params = {}
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0]
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc'
                }
                if (page !== undefined) {
                    params.page = page
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage
                }





                await this.$http
                    .get("admin/integration_data", {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.integration_dataItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalIntegrationDatas = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.$toastr.success(this.$t('failed_to_get_list_integration_datas'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async deleteIntegrationData(item) {
                if (confirm(this.$t('delete_integration_data'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .delete(`admin/integration_data/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('integration_data_has_been_deleted'))
                            this.getIntegrationDatas()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('integration_data_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            }
        }
    }
</script>
